import React from "react";
import { Navbar } from "../../components/Navbar/Navbar";
import "./Main.scss";
import logo from "../../assets/logo.jpg";

export const Main = () => {
    return (
        <div>
            <Navbar />
            <div className="main_container">
                <div className="flex_cont">
                    <h1 className="title">Hi check my video!</h1>
                    <a href="https://www.youtube.com/@_____FireAI" alt="https://www.youtube.com/@_____FireAI">
                        <img src={logo}/>
                    </a>
                </div>
                <div className="about_me">
                    <h1 className="about_me_title">About me</h1>
                    <h1>Communication: The English alphabet is used in written communication around the world. Knowing the alphabet will enable you to read and write in English, making it easier to communicate with others who use this language.</h1>
                    <h1>Education: If you want to pursue higher education in an English-speaking country, it is essential to have a strong foundation in the English language, which includes knowledge of the alphabet.</h1>
                    <h1>Career opportunities: Many companies require employees to have a good grasp of the English language, including the alphabet. Knowledge of the English alphabet can open up job opportunities in fields such as customer service, education, and business.</h1>
                    <h1>Cultural understanding: Learning the English alphabet can also help you better understand the culture of English-speaking countries. It can also help you appreciate the nuances of the language, including spelling and pronunciation.</h1>
                    <h1>In summary, learning the English alphabet is an important foundation for mastering the English language and can open up opportunities for communication, education, career advancement, and cultural understanding.</h1>
                </div>
                <div className="videos_container">
                    <div className="divider" />
                    <h1 className="best_title">My best videos</h1>
                    <div className="videos_c">
                        <iframe src="https://www.youtube.com/embed/oGpLKoNJQxs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe width="100%" src="https://www.youtube.com/embed/nKu3dLYuN3k?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe width="100%" src="https://www.youtube.com/embed/AR6_8STG7YY?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe width="100%" src="https://www.youtube.com/embed/Hf4lvwSGj58?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}